import { createContext } from "react";

import { GlobalContextInterface } from "./GlobalState.entities";

const defaultContext: GlobalContextInterface = {
  state: {
    token: "token",
    userId: "userId",
    isAuthenticated: false,
  },
  setState: () => {},
};

const GlobalContext = createContext<GlobalContextInterface>(defaultContext);

export default GlobalContext;
