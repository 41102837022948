import type { AppProps } from "next/app";
import "bootstrap/dist/css/bootstrap.min.css";
import "./app.css";
import GlobalState from "../global/GlobalState";
import NetowrkValidator from "../networkValidator";
import { useEffect } from "react";
import { ErrorTracking } from "../error_tracking/error_tracking";

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    ErrorTracking.initialise();
    console.log(`Running ${process.env.NEXT_PUBLIC_ENVIRONMENT} env`);
  }, []);

  return (
    <GlobalState>
      <NetowrkValidator>
        <Component {...pageProps} />
      </NetowrkValidator>
    </GlobalState>
  );
}

export default MyApp;
