import React from "react";
import { colour } from "../../global/theme";
import MoonLoader from "react-spinners/MoonLoader";
import styles from "./PageLoader.module.css";

type Props = {
  isLoading: boolean;
};
const PageLoader = ({ isLoading }: Props) => {
  return (
    <div className={styles.container}>
      <MoonLoader color={colour.interactive} loading={isLoading} size={40} />
    </div>
  );
};

export { PageLoader };
