import * as Sentry from "@sentry/nextjs";

export class ErrorTracking {
  static initialise() {
    const isSentryisInitialized = Sentry.isInitialized();
    if (!isSentryisInitialized) {
      console.info("ℹ️ Initialise Sentry");

      const sentryDSN =
        process.env.NEXT_PUBLIC_ENVIRONMENT != "local"
          ? process.env.NEXT_PUBLIC_SENTRY_DSN
          : "";

      Sentry.init({
        dsn: sentryDSN,
        // Replay may only be enabled for the client-side
        integrations: [Sentry.replayIntegration()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        environment: process.env.NEXT_PUBLIC_ENVIRONMENT,

        // ...

        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
      });
    }
  }
  static setUser(userId: String): void {
    console.info(`ℹ️ Set user ${userId}`);
    Sentry.withScope((scope) => scope.setUser(userId));
  }

  static captureError(message: string): void {
    console.warn(`⚠️ Capture Error Message ${message}`);
    Sentry.captureMessage(message, { level: "error" });
  }

  static errorBreadcrumb(message: string): void {
    console.info(`⚠️ Capture Error Breadcrumb ${message}`);

    Sentry.addBreadcrumb({
      type: "error",
      level: "error",
      message,
    });
  }
  static infoBreadcrumb(message: string): void {
    console.info(`ℹ️ Capture Info Breadcrumb ${message}`);

    Sentry.addBreadcrumb({
      type: "info",
      level: "info",
      message,
    });
  }
}
