import styles from "./emptyPqgeView.module.css";
import Image from "next/image";
import EmptyProposal from "../../assets/empty_proposal.svg";
import ErrorImage from "../../assets/error_image.svg";
import React from "react";
import BaseButton from "../buttons/baseButton";
import BaseText from "../baseText";

type Props = {
  onButtonPress: () => void;
  type?: EmptyViewType;
};

export enum EmptyViewType {
  Empty = "empty",
  Error = "error",
}

const iconMap = {
  [EmptyViewType.Empty]: EmptyProposal,
  [EmptyViewType.Error]: ErrorImage,
};
const buttonTextMap = {
  [EmptyViewType.Empty]: "Create Proposal",
  [EmptyViewType.Error]: "Retry",
};

const messageTextMap = {
  [EmptyViewType.Empty]: "You don't have any proposals on the blockchain yet.",
  [EmptyViewType.Error]:
    "Oh no, something went wrong, please check your connection and try again.",
};

const EmptyPageView = ({
  onButtonPress,
  type = EmptyViewType.Empty,
}: Props) => {
  return (
    <div className={styles.noProposalView}>
      <Image src={iconMap[type]} height={400} width={400} />
      <BaseText className={styles.noProposalText} text={messageTextMap[type]} />
      {
        // TODO: Refactor to button style primary
      }
      <BaseButton className={styles.newIdea} onClick={onButtonPress}>
        {buttonTextMap[type]}
      </BaseButton>
    </div>
  );
};
export { EmptyPageView };
