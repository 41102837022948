import Image from "next/image";
import blockchainErrorImage from "../../assets/blockchain_error.svg";
import BaseText, { FontWeight, TextSize } from "../../components/baseText";
import {
  getExpectedNetworkName,
  getNetworkName,
} from "../../utils/blockchainConfig";
import styles from "./InvalidNetworkView.module.css";

type Props = {
  chainId: number;
};

const InvalidNetworkView = ({ chainId }: Props) => {
  const currentNetworkName = getNetworkName(chainId);
  const expectedNetworkName = getExpectedNetworkName();
  const getCurrentNetworkPhrase = currentNetworkName
    ? `the ${currentNetworkName}`
    : "an unknown";

  return (
    <div className={styles.incorrectNetwork}>
      <div>
        <BaseText
          text="Blockposal.io"
          size={TextSize.Title}
          fontWeight={FontWeight.SemiBold}
          className={styles.centerText}
        />
        <div className={styles.titleBlockContainer}>
          <div className={styles.titleBlock} />
        </div>
        <div className={styles.image}>
          <Image src={blockchainErrorImage} height={300} width={400} />
        </div>
        <BaseText
          text="Invalid Network"
          size={TextSize.Title}
          className={styles.title}
          fontWeight={FontWeight.Regular}
        />

        <BaseText
          text={`You are currently connected to ${getCurrentNetworkPhrase} network on Metamask`}
          size={TextSize.Large}
          className={styles.centerText}
        />
        <BaseText
          text={`please connect to the ${expectedNetworkName} network to continue.`}
          size={TextSize.Large}
          className={styles.centerText}
        />
      </div>
    </div>
  );
};

export { InvalidNetworkView };
