import React from "react";

type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: any;
  children: React.ReactNode;
};
export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps) => (condition ? wrapper(children) : children);

export const conditionalRender = (
  expression: boolean,
  componentWhenTrue: React.ReactNode,
  componentWhenFalse: React.ReactNode | null = null
) => {
  return expression ? componentWhenTrue : componentWhenFalse;
};

export interface Event<T = EventTarget> {
  target: T;
}

export const mergeArray = (array: any[]) => array.join(" ");
