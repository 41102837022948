const palette = {
  RED: "#f93e3e",
  PURPLE: "#645ef3",
  BLUE: "#0d6efd",
  GREY: "#cacaca",
  WHITE: "#fff",
  OFF_WHITE: "#f9fafd",
  YELLOW: "#FFBB03",
  OPAQUE_PURPLE: "#e2e6ff",
  BLACK: "#000000",
  GREEN: "#2EC339",
  DARK_GREY: "#727272",
};

export const colour = {
  error: palette.RED,
  interactive: palette.PURPLE,
  highlight: palette.BLUE,
  background: palette.OFF_WHITE,
  card: palette.WHITE,
  disabled: palette.GREY,
  warning: palette.YELLOW,
  secondaryHighlight: palette.OPAQUE_PURPLE,
  black: palette.BLACK,
  white: palette.WHITE,
  success: palette.GREEN,
  blackHighlight: palette.DARK_GREY,
};
