import { useEffect, useState } from "react";
import EmptyPageView, { EmptyViewType } from "../components/emptyPageView";
import { useGlobalState, useLogOut } from "../global/hooks";
import {
  getCurrentBlockchainId,
  getExpectedBlockchainId,
} from "../utils/blockchainConfig";
import { conditionalRender } from "../utils/helpers";
import InvalidNetworkView from "./InvalidNetworkView";
import PageLoader from "../components/pageLoaderView";

type Props = {
  children: React.ReactNode;
};

const BLOCKCHAIN_NETWORK_CHANGE_EVENT = "chainChanged";
const BLOCKCHAIN_ACCOUNT_CHANGE_EVENT = "accountsChanged";

const NetowrkValidator = ({ children }: Props) => {
  const [isCorrectBlockchain, setIsCorrectBlockchain] = useState<
    boolean | undefined
  >();

  const [currentBlockchainId, setCurrentBlockchainId] = useState<
    number | undefined
  >();
  const [isMetamaskUnlocked, setIsMetamaskUnlocked] = useState(true);
  const expectedBlockchainId = getExpectedBlockchainId();
  const { logout } = useLogOut();
  const [isLoading, setIsLoading] = useState(true);
  const [isWeb3Available, setIsWeb3Avaiable] = useState(true);
  const globalState = useGlobalState();

  const safelyGetEthereum = () => {
    const { ethereum } = window as any;
    if (!ethereum) {
      setIsWeb3Avaiable(false);
      setIsLoading(false);
      return undefined;
    }
    setIsWeb3Avaiable(true);
    return ethereum;
  };

  // Used to reload window when network changes
  const addNetworkChangeListener = () => {
    const ethereum = safelyGetEthereum();
    if (!ethereum) return;
    ethereum.on(BLOCKCHAIN_NETWORK_CHANGE_EVENT, () => {
      logout();
      window.location.reload();
    });
  };

  // Used to log user out when disconnecting their wallet
  const addAccountChangeListener = () => {
    const ethereum = safelyGetEthereum();
    if (!ethereum) return;
    ethereum.on(BLOCKCHAIN_ACCOUNT_CHANGE_EVENT, (accounts: any[]) => {
      if (accounts.length === 0 || globalState.state.isAuthenticated) {
        logout();
      }
    });
  };

  const setup = async () => {
    const ethereum = safelyGetEthereum();
    if (!ethereum) return;
    setIsLoading(true);
    const currentBlockchainId = await getCurrentBlockchainId();
    setIsLoading(false);
    setCurrentBlockchainId(currentBlockchainId);
    addNetworkChangeListener();
    addAccountChangeListener();
    const isUnlocked = await ethereum._metamask.isUnlocked();
    setIsMetamaskUnlocked(isUnlocked);
  };

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    setIsCorrectBlockchain(currentBlockchainId === expectedBlockchainId);
  }, [currentBlockchainId]);

  useEffect(() => {
    if (isMetamaskUnlocked) return;
    logout();
  }, [isMetamaskUnlocked]);

  if (isLoading) {
    return <PageLoader isLoading={isLoading} />;
  }

  if (!isWeb3Available) return <>{children}</>;

  return (
    <>
      {conditionalRender(
        !!isCorrectBlockchain,
        children,
        conditionalRender(
          currentBlockchainId !== undefined,
          <InvalidNetworkView chainId={currentBlockchainId!} />,
          <EmptyPageView type={EmptyViewType.Error} onButtonPress={setup} />
        )
      )}
    </>
  );
};

export { NetowrkValidator };
