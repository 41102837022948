import React from "react";
import styles from "./BaseButton.module.css";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { colour } from "../../../global/theme";

type Props = {
  onClick?: () => void;
  style?: React.CSSProperties;
  children: React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string | undefined;
  loaderColour?: string;
};
const BaseButton = ({
  style,
  onClick,
  children,
  disabled,
  isLoading,
  className,
  loaderColour = colour.white,
}: Props) => {
  const buttonStyle = [styles.container, className].join(" ");

  const renderLoader = () => {
    return <ClipLoader color={loaderColour} size={24} loading={isLoading} />;
  };
  return (
    <button
      style={style}
      disabled={disabled || isLoading}
      className={buttonStyle}
      onClick={onClick}
    >
      {isLoading ? renderLoader() : children}
    </button>
  );
};

export { BaseButton };
