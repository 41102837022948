import { useState, ReactNode, useEffect } from "react";

import GlobalContext from "../global/context";
import {
  ETHEREUM_PUBLIC_KEY_STORAGE_KEY,
  TOKEN_STORAGE_KEY,
  USER_ID_STORAGE_KEY,
} from "./constants";
import { GlobalStateInterface } from "./GlobalState.entities";
import { ErrorTracking } from "../error_tracking/error_tracking";

interface GlobalStateProps {
  children: ReactNode;
}

type GlobalState = {
  token: string | null;
  userId: string | null;
  isAuthenticated: boolean | null;
  ethPublicKey: string | null;
};

const GlobalState = (props: GlobalStateProps) => {
  const isClient = typeof window !== "undefined" ? true : undefined;
  const token =
    isClient && (localStorage.getItem(TOKEN_STORAGE_KEY) ?? undefined);
  const userId =
    isClient && (localStorage.getItem(USER_ID_STORAGE_KEY) ?? undefined);
  const ethPublicKey =
    isClient &&
    (localStorage.getItem(ETHEREUM_PUBLIC_KEY_STORAGE_KEY) ?? undefined);

  console.log({ token });
  console.log({ ethPublicKey });

  useEffect(() => {
    if (token && ethPublicKey) {
      ErrorTracking.setUser(ethPublicKey);
    }
  }, [ethPublicKey, token]);

  const initializeState: GlobalStateInterface = {
    token: token,
    userId: userId,
    isAuthenticated: !!token,
    ethPublicKey: ethPublicKey,
  };

  const [globalState, setGlobalState] =
    useState<GlobalStateInterface>(initializeState);

  return (
    <GlobalContext.Provider
      value={{ state: globalState, setState: setGlobalState }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
export default GlobalState;
